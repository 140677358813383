import { isDev } from '@core/constants';
import { InfinityProgress } from '@ui/infinity-progress';
import 'core-js/actual/array/at';
import { attachLogger } from 'effector-logger';
import { createRoot } from 'react-dom/client';

const MOUNT_NODE_ID = 'root';
const rootEl = createRoot(document.getElementById(MOUNT_NODE_ID) as HTMLElement);

const renderProgress = () => rootEl.render(<InfinityProgress />);

const init = async () => {
  renderProgress();

  try {
    const { renderApp } = await import('./main');

    rootEl.unmount();
    renderApp(MOUNT_NODE_ID);
  } catch (error) {
    const errorText = 'Failed to load application.\nPlease check your connection and reload page.';
    rootEl.unmount();
    renderError(errorText);
    console.error(error);
  }
};

const renderError = (errorText: string) => {
  const errorMessage = document.createElement('div');
  errorMessage.style.width = '100%';
  errorMessage.style.height = '100vh';
  errorMessage.style.display = 'flex';
  errorMessage.style.justifyContent = 'center';
  errorMessage.style.alignItems = 'center';
  errorMessage.style.textAlign = 'center';
  errorMessage.style.color = 'white';
  errorMessage.style.fontFamily = 'Rubik, sans-serif';
  errorMessage.innerText = errorText;

  document.getElementById(MOUNT_NODE_ID)?.appendChild(errorMessage);
};

init();

if (isDev) {
  // @ts-ignore
  import('./eruda').then(({ default: eruda }) => {
    // nothing;
  }); //runtime download

  attachLogger();
}
