import '@sensearena/ui/theme.css';
import { loader } from './style.css';

type Props = {
  disableStyle?: boolean;
};

export const InfinityProgress = ({ disableStyle }: Props) => (
  <div className={loader} style={disableStyle ? undefined : { margin: '40vh auto 0' }} />
);
